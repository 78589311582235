import React, {useCallback, useEffect} from "react";
import {Dialog} from "../../dialogs";
import {connect} from "react-redux";
import {withTranslation, WithTranslation} from "react-i18next";
import {MailResult} from "../../../types/MailResult";
import {MESSAGE_PATH} from "../../../routes";
import {RootState, useAppDispatch} from "../../../store/store";
import moment from "moment/moment";
import {DatePicker} from "../../DatePicker";
import {createPermalink} from "../../../store/thunks";
import {clearPermaToken} from "../../../store/MessageSlice";
import {toast} from "react-toastify";
import ReactDatePicker from "react-datepicker";

function getDefaultPermaDate() {
    return moment().add(1, 'day').add(5, "minutes").toDate();
}

const PermaLinkDialogComponent: React.FC<{ isOpen: boolean, message: MailResult, close: () => void } & WithTranslation>
    = ({isOpen, t, message, close}) => {

    const permaLinkInput = React.useRef<HTMLInputElement>(null);
    const [expiryDate, setExpiryDate] = React.useState<Date>(getDefaultPermaDate());
    const [inputDate, setInputDate] = React.useState<Date | null>(null);
    const dispatch = useAppDispatch()
    const dateInputRef = React.useRef<ReactDatePicker>(null);

    function createPermaLink(token: string): string {
        return `${window.location.origin.toString()}${MESSAGE_PATH}/${token}`;
    }

    const createDebouncedPermalink = useCallback(() => {
        const handler = setTimeout(() => {
            dispatch(createPermalink({id: message.bennoId, expiryDate: expiryDate}));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [expiryDate, message.bennoId, dispatch]);

    useEffect(() => {
        return createDebouncedPermalink();
    }, [createDebouncedPermalink]);

    console.log(dateInputRef)

    function validateAndSetDate(date: Date | null) {
        dispatch(clearPermaToken());
        if (date instanceof Date) {
            setExpiryDate(date);
            if (moment(date).isBefore(moment())) {
                toast(t('MSG_EXPIRY_DATE_IN_PAST') as string, {type: 'error'});
                setExpiryDate(getDefaultPermaDate());
            }
        }
    }

    return <Dialog isOpen={isOpen} close={close} title={t('MSG_PERMALINK')} buttons={[
        // {text: t('LABEL_OK'), isMain: true, class: 'ui-button-fullwidth'}
    ]}>
        <label>{t('TXT_EXPIRYDATE')}:</label>
        <DatePicker
            ref={dateInputRef}
            minDate={moment().toDate()} wrapperClassName=''
            filterTime={(date) => moment(date).isAfter(moment())}
            tabIndex={6} id='expiry-date'
            selected={expiryDate}
            showTimeSelect={true}
            onCalendarClose={() => {
                if (inputDate) {
                    validateAndSetDate(inputDate);
                    setInputDate(null);
                }
            }
            }
            onChange={(date, event) => {
                if (event?.nativeEvent instanceof InputEvent) {
                    // do not react to keyboard input directly but when the calendar closes
                    setInputDate(date);
                    event.preventDefault();
                } else {
                    validateAndSetDate(date);
                }
            }}/>
        <input readOnly type='text' id='permalink' className='input_text readonly-input' ref={permaLinkInput}
               value={message?.permaToken ? createPermaLink(message?.permaToken) : t('MSG_LOADING')}/>
        <div style={{textAlign: "center"}}>
            <a id='dialog_permalink_copy' className='modal_button' onClick={() => {
                permaLinkInput?.current?.select();
                document.execCommand("copy");
            }}>{t('BTN_COPY_TO_CLIPBOARD')}</a>
        </div>
    </Dialog>;
};

export const PermaLinkDialog = connect((rootState: RootState) => {
    return {};
})(withTranslation()(PermaLinkDialogComponent));
