import {SearchParametersState, setPage, setSorting} from "../store/SearchParametersSlice";
import {SortingValue, updateSearch} from "../store/thunks";
import React, {useRef} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {SearchPanel} from "../ui/search/SearchPanel";
import {withTranslation, WithTranslation} from "react-i18next";
import {Sidebar} from "../ui/Sidebar";
import {MessageListControlPanel} from "../ui/list/MassageListControlPanel";
import {MessageList} from "../ui/list/MessageList";
import {MessageControlPanel} from "../ui/message/MessageControlPanel";
import {MessageContent} from "../ui/message/MessageContent";
import {MultipartItem} from "../types/MessageBody";
import {RootState, useAppDispatch} from "../store/store";
import {SearchResultState} from "../store/SearchResultSlice";
import {MessageState, selectMessageLoadedAndSelectedByUser, unselectMessage} from "../store/MessageSlice";
import {useReactToPrint} from "react-to-print";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {cloneShadowRoots} from "../ui/message/cloneEmail";
import {Scrollbars} from "rc-scrollbars";
import {PrintSetup} from "../ui/message/components/PrintSetup";

export type BennoPage = 'queries' | 'search' | 'profile'

export function getActivePage(path: string): BennoPage {
    if (path === '/queries') {
        return 'queries'
    }
    if (path === '/profile') {
        return 'profile'
    }
    return 'search'
}

type AppUiProps = {
    searchResult: SearchResultState,
    messageState: MessageState,
    searchParameters: SearchParametersState,
    notSelectedClass: string,
    searchExplicitlyOpened: boolean
}

function mapStateToProps(state: RootState): AppUiProps {
    const {searchResult, message, searchParameters} = state
    return {
        notSelectedClass: selectMessageLoadedAndSelectedByUser(message) ? '' : ' not-selected',
        searchExplicitlyOpened: state.uiState.searchExplicitlyOpened,
        searchResult: searchResult,
        messageState: message,
        searchParameters: searchParameters
    }
}

export const _SearchPage: React.FC<AppUiProps & WithTranslation> = ({
                                                                        searchResult, t,
                                                                        messageState, searchParameters,
                                                                        notSelectedClass,
                                                                        searchExplicitlyOpened
                                                                    }) => {
    const dispatch = useAppDispatch()
    const path = useLocation().pathname
    const activePage = getActivePage(path)
    const infiniteScrollRef = useRef<InfiniteScroll>(null)
    const contentDivRef = useRef<HTMLDivElement>(null);

    const {scrollbarsRef, onPrint} = PrintSetup(contentDivRef);

    const bennoContainer = searchParameters.bennoContainer;

    const searchFormHidden = searchParameters.searchFormHidden
    const onSearchClicked = () => {
        infiniteScrollRef.current?.getScrollableTarget()?.scrollTo({top: 0})
    }
    const toogleShowSavedSearches = (value: boolean) => {
    }
    const searchForm = searchParameters.searchForm
    const total = searchResult.total
    const sorting = searchParameters.sorting
    const updateSorting = (sorting: SortingValue) => {
        infiniteScrollRef.current?.getScrollableTarget()?.scrollTo({top: 0})
        dispatch(setSorting(sorting))
        if (bennoContainer) {
            dispatch(updateSearch({container: bennoContainer, resetMessages: true}))
        }
    }
    const page = searchParameters.page
    const updatePage = (page: number) => {
        dispatch(setPage(page))
        if (bennoContainer) {
            dispatch(updateSearch({
                container: bennoContainer,
                resetMessages: false
            }))
        }
    }
    const message = messageState.message
    const loading = messageState.loading
    const error = messageState.error

    let onMessageClose = () => {
        dispatch(unselectMessage())
    };

    return <div id="stage" className={searchExplicitlyOpened ? 'b-search-mobile' : ''}>
        <div id="stage-inner">
            <div id="panel"
                 className={"stage_section" + (searchFormHidden ? " search-form-hidden" : "")}>
                <Sidebar/>
                {!searchFormHidden &&
                    <SearchPanel activePage={activePage}
                                 onSearchClicked={() => onSearchClicked()}
                                 toogleShowSavedSearches={toogleShowSavedSearches}
                                 searchForm={searchForm}/>}
            </div>
            <div
                className={"stage-right" + (searchFormHidden ? " search-form-hidden" : "")}>

                <div id="overview" className={"stage_section" + ((total !== undefined) ? '' : ' not-loaded')}>
                    <MessageListControlPanel total={total}
                                             sorting={sorting}
                                             setSorting={(sorting: SortingValue) => {
                                                 updateSorting(sorting)
                                             }}/>
                    <MessageList
                        infiniteScrollRef={infiniteScrollRef}
                        page={page}
                        setPage={updatePage}
                        total={total || 0}/>
                </div>
                <div id="mailcontent" className={"stage_section" + notSelectedClass}>
                    <div id="mailcontent-inner">
                        {message && <>
                            <MessageControlPanel details={message} onPrint={onPrint} close={onMessageClose}/>
                            <MessageContent contentDivRef={contentDivRef}
                                            message={message}
                                            scrollbarsRef={scrollbarsRef}
                                            loading={loading}
                                            error={error}/>
                        </>}
                        {message == undefined && <>
                            <MessageContent
                                scrollbarsRef={scrollbarsRef}
                                message={{
                                    container: '',
                                    bennoId: "",
                                    date: "",
                                    fromEmail: "",
                                    toEmails: [],
                                    subject: '',
                                    ccEmails: [],
                                    attachments: [],
                                    checksum: false,
                                    rawUrl: "",
                                    hasAttachment: 0,
                                    body: [{
                                        data: t('GREETING_CONTENT')
                                    } as MultipartItem]
                                }} loading={false}/>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export const SearchPage = connect(mapStateToProps)(withTranslation()(_SearchPage))