import {useParams} from "react-router-dom";
import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import {getMessageWithToken} from "../../store/thunks";
import {MailResult} from "../../types/MailResult";
import {RootState, useAppDispatch} from "../../store/store";
import {MessageContent} from "./MessageContent";
import {withTranslation, WithTranslation} from "react-i18next";
import {BennoError} from "../../store/common";
import {MessageControlPanel} from "./MessageControlPanel";
import {Scrollbars} from "rc-scrollbars";
import MessageOnlyHeader from "./MessageOnlyHeader";
import {PrintSetup} from "./components/PrintSetup";

export const MessageOnlyPanelComponent: React.FC<{ message?: MailResult, error?: BennoError } & WithTranslation>
    = ({message, t, error}) => {
    const {token} = useParams<{ token: string }>()

    const [messageLoadingStarted, setMessageLoadingStarted] = useState(false)
    const dispatch = useAppDispatch()

    const contentDivRef = useRef<HTMLDivElement>(null);

    const { scrollbarsRef, onPrint } = PrintSetup(contentDivRef);

    if (!messageLoadingStarted) {
        dispatch(getMessageWithToken(token!!))
        setMessageLoadingStarted(true)
    }

    if (error) {
        return <div className="notification-message"><i className="fa-solid fa-circle-exclamation"></i>
            <p>{t(error.messageCode)}</p></div>
    }

    if (!message) {
        return <div className="notification-message"><p>{t('LABEL_LOADING')}</p></div>
    }

    return <>
        <div id="stage-message-only" className={''}>
            <MessageOnlyHeader/>
            <div id="mailcontent-message-only" className={"stage_section"}>
                <div id="mailcontent-message-only-inner">
                    <MessageControlPanel details={message} messageOnly={true} onPrint={onPrint}/>
                    <MessageContent contentDivRef={contentDivRef}
                                    scrollbarsRef={scrollbarsRef}
                                    token={token!!}
                                    message={message}
                                    loading={false}
                                    error={error}/>
                </div>
            </div>
        </div>
    </>

    /** Old working code
     * return <MessageContent message={message}
     loading={false}
     token={token!!}
     error={error}/>**/
}

export const MessageOnlyPanel = connect((state: RootState) => {
    return {
        message: state.message.message,
        error: state.message.error
    }
})(withTranslation()(MessageOnlyPanelComponent))