import React from 'react';
//@ts-ignore
import logo from '../header/logo_benno.jpg';
import {Link} from "react-router-dom";
import {HOME_PATH} from "../../routes";

interface PermalinkHeaderProps {
    className?: string,
}

const getLogoPath = (logo: string): string => {
    let logoPath = logo;
    if (logoPath.startsWith('./')) {
        logoPath = logoPath.substring(1); // Remove the starting dot
    }
    return logoPath;
};

const MessageOnlyHeader: React.FC<PermalinkHeaderProps> = () => {
    //Workaround for showing images with another path than the root path
    let logoPath = getLogoPath(logo);

    return (
        <header id={'header-message-only'}>
            <div id={'header_logo'}>
                <div><Link tabIndex={-1} to={HOME_PATH}><img alt='Benno Mail Archiv'
                                                             src={logoPath}/></Link></div>
            </div>
        </header>
    );
};

export default MessageOnlyHeader;

