import i18n from 'i18next'
import {initReactI18next} from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/zh-cn';
import {de} from "date-fns/locale/de";
import {fr} from "date-fns/locale/fr";
import {it} from "date-fns/locale/it";
import {pl} from "date-fns/locale/pl";
import {zhCN} from "date-fns/locale/zh-CN";

import deTranslations from './i18n/de.json'
import deLGTranslations from './i18n/de_LG.json'
import cnTranslations from './i18n/cn.json'
import itTranslations from './i18n/it.json'
import plTranslations from './i18n/pl.json'
import enTranslations from './i18n/en.json'
import moment from 'moment';
import {registerLocale} from "react-datepicker";

// the translations
function createTranslationMap(source: Array<any>) {
    const translations = {}
    source.forEach((translation) => {
        const text = translation['translation']
        // @ts-ignore
        translations[translation['key']] = Array.isArray(text) ? text.join("\n") : text
    })
    return {
        translation: translations
    }
}

const resources = {
    de: createTranslationMap(deTranslations),
    de_LG: createTranslationMap(deLGTranslations),
    en: createTranslationMap(enTranslations),
    cn: createTranslationMap(cnTranslations),
    it: createTranslationMap(itTranslations),
    pl: createTranslationMap(plTranslations)
};

// set language from local storage

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'], // Define the order of the detection methods
            lookupLocalStorage: 'i18nextLng', // Key in localStorage where the language is stored
            caches: ['localStorage'] // Specify where to cache the language information
        },
    })

registerLocale('de', de)
registerLocale('fr', fr)
registerLocale('it', it)
registerLocale('pl', pl)
registerLocale('zh-CN', zhCN)

moment.locale(i18n.language);

i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
});

export const bennoI18n = i18n
const momentToDateFnsMap: { [key: string]: string } = {
    'YYYY': 'yyyy',
    'YY': 'yy',
    'MMMM': 'MMMM',
    'MMM': 'MMM',
    'MM': 'MM',
    'M': 'M',
    'DD': 'dd',
    'D': 'd',
    'dddd': 'EEEE',
    'ddd': 'EEE',
    'HH': 'HH',
    'H': 'H',
    'hh': 'hh',
    'h': 'h',
    'mm': 'mm',
    'ss': 'ss',
    'A': 'aa',
    'a': 'aa',
};

export const convertMomentToDateFnsFormat = (momentFormat: string): string => {
    // Use a regular expression to match and replace Moment.js format tokens
    return momentFormat.replace(
        /YYYY|YY|MMMM|MMM|MM|M|DD|D|dddd|ddd|HH|H|hh|h|mm|ss|A|a/g,
        (match: string) => momentToDateFnsMap[match] || match
    );
};