import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import moment from 'moment'
import {useDispatch} from "react-redux";
import i18next from "i18next";

import {setEndDate as setEndDateAction, setStartDate as setStartDateAction} from "../../../store/SearchParametersSlice"
import {convertMomentToDateFnsFormat} from "../../i18n";
import {DatePicker} from "../../DatePicker";

const _DateSearchPanel: React.FC<{
    startDate?: Date,
    endDate?: Date
} & WithTranslation> = ({startDate, endDate, t}) => {
    const dispatch = useDispatch()

    function setStartDate(date: Date | [Date, Date] | null) {
        if (date instanceof Date || date == null) {
            dispatch(setStartDateAction(date))
        }
    }

    function setEndDate(date: Date | [Date, Date] | null) {
        if (date instanceof Date || date == null) {
            dispatch(setEndDateAction(date))
        }
    }

    function setDates(period: string) {
        if ("today" == period) {
            setStartDate(new Date())
            setEndDate(new Date())
        }

        if ("week" == period) {
            setStartDate(moment().startOf('isoWeek').toDate())
            setEndDate(moment().endOf('isoWeek').toDate())
        }

        if ("month" == period) {

            setStartDate(moment().startOf('month').toDate())
            setEndDate(moment().endOf('month').toDate())
        }

        if ("quarter" == period) {

            setStartDate(moment().startOf('quarter').toDate())
            setEndDate(moment().endOf('quarter').toDate())
        }

        if ("year" == period) {

            setStartDate(moment().startOf('year').toDate())
            setEndDate(moment().endOf('year').toDate())
        }
    }

    function createSetDatesButton(period: string, abbrev: string) {
        return <a title={abbrev} className='search_min_button' onClick={() => {
            setDates(period)
        }}>{abbrev}</a>
    }

    const dateFormat = convertMomentToDateFnsFormat(moment.localeData().longDateFormat('L'));

    return <>
        <div className='row'>
            <div className='col-12 col-sm-6'>

                <label className='input_label label_ex_start-date'
                       htmlFor='start-date'>{t('SEARCH_STARTDATE')}</label>
                <DatePicker minDate={moment().subtract(11, "years").toDate()} wrapperClassName=''
                            tabIndex={5} id='start-date'
                            selected={startDate}
                            showPreviousMonths={false}
                            maxDate={new Date()}
                            onChange={date => setStartDate(date)}/>

            </div>
            <div className='col-12 col-sm-6'>

                <label className='input_label label_ex_end-date' htmlFor='end-date'>{t('SEARCH_ENDDATE')}</label>
                <DatePicker minDate={moment().subtract(11, "years").toDate()} wrapperClassName=''
                            tabIndex={6} id='end-date'
                            selected={endDate}
                            maxDate={new Date()}
                            onChange={date => setEndDate(date)}/>
            </div>
        </div>

        <div className='search_min_button_holder'>
            {/* <label className='input_label label_searchmin_quickbuttons'>{t('SEARCH_LABEL_QUICKBUTTONS')}</label> */}
            <div className='search_min_button_links'>
                {createSetDatesButton('today', t('SELECT_today'))}
                {createSetDatesButton('week', t('SELECT_week'))}
                {createSetDatesButton('month', t('SELECT_month'))}
                {createSetDatesButton('quarter', t('SELECT_quarter'))}
                {createSetDatesButton('year', t('SELECT_year'))}
            </div>
        </div>

    </>
}

export const DateSearchPanel = withTranslation()(_DateSearchPanel)
