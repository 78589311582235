import React from "react"
import {withTranslation, WithTranslation} from "react-i18next";

const SpamSearchPanel: React.FC<WithTranslation & {
    spamAlso: boolean,
    onChange: (value: boolean) => void
}> = (props) => {

    {/*}
    return <div className='input_checkbox_wrapper'>
        <label className='input_label label_ex_spam'
               htmlFor='isSpam'>{props.t('SEARCH_SPAM')}</label>
        <input className='input_checkbox'
               type='checkbox'
               checked={props.spamAlso}
               onChange={event => {props.onChange(event.target.checked)}}
               tabIndex={10}
               value='on'/>
    </div>
*/
    }

    return <div className='pretty p-switch p-fill pretty-custom'>
        <input type='checkbox'
               id='isSpam'
               onChange={event => {
                   props.onChange(event.target.checked)
               }}
               checked={props.spamAlso}
               tabIndex={10}
               value='on'/>
        <div className="state">
            <label htmlFor='isSpam'><span className="icon-bug2"></span> {props.t('SEARCH_SPAM')}</label>
        </div>
    </div>

}

export default withTranslation()(SpamSearchPanel)
