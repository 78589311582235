export enum UserRole {
    SYSTEM = 'SYSTEM',
    AUTH = 'AUTH', USER = 'USER',
    REVISOR = 'REVISOR',
    ADMIN = 'ADMIN'
}

export interface BennoContainer {
    name: string;
}

export interface User {
    username: string
    displayUsername: string
    containers: string[]
    role: UserRole
    password?: string
    mails: Array<string>
}

export interface AuthenticatedUser extends User {
    jwtToken?: string;
    archives?: Array<BennoContainer>;
}
