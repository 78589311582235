// MessageSourceDialog.tsx
import React, {useEffect, useState} from "react";
import {Dialog} from "../../dialogs";
import {bennoBackend} from "../../../store/thunks";
import {MailResult} from "../../../types/MailResult";
import {withTranslation, WithTranslation} from "react-i18next";

export const MessageSourceDialogComponent: React.FC<{
    isOpen: boolean,
    message: MailResult,
    close: () => void
} & WithTranslation>
    = ({isOpen, t, message, close}) => {
    const [textContent, setTextContent] = useState('');

    const downloadMessageSource = async () => {
        const blob = await bennoBackend.getRawMessage(message.container, message.bennoId, false);
        const text = await blob.text();
        setTextContent(text);
    };

    useEffect(() => {
        downloadMessageSource();
    }, [message.bennoId]);

    return <Dialog isOpen={isOpen} close={close} title={t('MSG_SOURCE')} modalStyle={{
        content: {
            width: '80%',
            transform: 'translate(-50%, -38%)',
            height: '90%',
            display: 'block',
        }
    }}>
        <div style={{
            width: '100%'
        }}>{textContent && <pre>{textContent}</pre>}</div>
    </Dialog>;
};

export const MessageSourceDialog = withTranslation()(MessageSourceDialogComponent);
