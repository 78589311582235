// ForwardMailDialog.tsx
import React, {useState} from "react";
import {Dialog} from "../../dialogs";
import {connect} from "react-redux";
import {forwardEmail} from "../../../store/thunks";
import {MailResult} from "../../../types/MailResult";
import {RootState, useAppDispatch} from "../../../store/store";
import {useAuth} from "react-oidc-context";
import {withTranslation, WithTranslation} from "react-i18next";

function validateEmail(email: string): boolean {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    return !!email.match(pattern);
}

const ForwardMailDialogComponent: React.FC<{
    isOpen: boolean, message: MailResult,
    close: () => void,
    bennoContainer?: string,
    asyncLoading: boolean
} & WithTranslation>
    = ({t, isOpen, message, close, bennoContainer, asyncLoading}) => {

    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const auth = useAuth();
    const [forwardTo, setForwardTo] = useState(auth.user?.profile.email || '');

    return bennoContainer && <Dialog isOpen={isOpen} close={close} title={t('BOX_MAILFORWARD')} buttons={[
        {
            text: t('LABEL_OK'), onClick: () => {
                if (validateEmail(forwardTo)) {
                    setError('');
                    setForwardTo('');
                    dispatch(forwardEmail({
                        recipientAddresses: [forwardTo],
                        bennoId: message.bennoId,
                        container: bennoContainer
                    }));
                    return true;
                } else {
                    setError(t('MSG_INVALIDEMAIL'));
                    return false;
                }
            }, isMain: true, class: ''
        },
        {
            text: t('LABEL_CANCEL'), isMain: false, class: '', onClick: () => {
                setError('');
                setForwardTo('');
                return true;
            }
        }
    ]}>
        <label>{t('MSG_RECIPIENT_ADDRESS')}:</label>
        <input tabIndex={-1} name='wl_mailto' id='wl_mailto' type='text' className='input_text'
               style={{border: '1px solid rgb(204, 204, 204)'}} onChange={event => setForwardTo(event.target.value)}
               value={forwardTo}/>
        {error && <p className='validation-error'>{error}</p>}
    </Dialog> || <></>;
};

export const ForwardMailDialog = connect((rootState: RootState) => {
    return {
        bennoContainer: rootState.searchParameters.bennoContainer,
        asyncLoading: rootState.uiState.asyncLoading,
    };
})(withTranslation()(ForwardMailDialogComponent));
