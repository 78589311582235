import React from 'react'
import ReactModal, {OnAfterOpenCallback} from 'react-modal'
import './dialogs.css'

const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '300px',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
    }
} as ReactModal.Styles;

interface DialogButton {
    isMain: boolean;
    text: string;
    class: string;
    active?: boolean;
    onClick?: () => boolean;
}

export function Dialog(props: {
    title: string, children: React.ReactNode, buttons?: Array<DialogButton>,
    isOpen: boolean,
    close: () => void,
    onAfterOpen?: OnAfterOpenCallback,
    modalStyle?: ReactModal.Styles
}) {
    return <ReactModal isOpen={props.isOpen}
                       onAfterOpen={props.onAfterOpen}
                       onRequestClose={props.close}
                       style={{
                           content: Object.assign({}, customStyles.content, props.modalStyle?.content),
                           overlay: Object.assign({}, customStyles.overlay, props.modalStyle?.overlay)
                       }}
                       ariaHideApp={false}
                       className='ui-dialog'>
        <div className='ui-dialog-titlebar ui-corner-all ui-widget-header ui-helper-clearfix ui-draggable-handle'>
            <span id='ui-id-1' className='ui-dialog-title'>{props.title}</span>
            <button type='button'
                    className='ui-button ui-corner-all ui-widget ui-button-icon-only ui-dialog-titlebar-close'
                    title='Close' onClick={event => props.close()}><span
                className='icon-cross'/>
            </button>
        </div>
        <div
            className={'ui-dialog-content ui-widget-content ui-modal-dialog-content' + (!props.buttons ? ' ui-content-no-button-bar' : ' ui-content-with-button-bar')}>
            {props.children}
        </div>
        { props.buttons && props.buttons.length > 0 ?
        <div className='ui-dialog-buttonpane ui-widget-content ui-helper-clearfix'>
            <div className='ui-dialog-buttonset'>
                {props.buttons?.map((button) => {
                    return <button key={button.text} type='button'
                                   className={'ui-button ui-corner-all ui-widget ' + button.class}
                                   disabled={button.active !== undefined && !button.active}
                                   onClick={((evt) => {
                                       if (button.onClick) {
                                           if (button.onClick()) {
                                               props.close()
                                           }
                                       } else {
                                           props.close()
                                       }
                                   })}>{button.text}</button>
                })}
            </div>
        </div> : <></>}
        {/*</div>*/}
    </ReactModal>;
}

