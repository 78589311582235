import React, {RefObject} from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {MessageBodyContent} from "./MessageBodyContent";
import {MailResult} from "../../types/MailResult";
import {Scrollbars} from "rc-scrollbars";
import {PulseLoader} from "react-spinners";
import {BennoError} from "../../store/common";

const MessageContentComponent: React.FC<WithTranslation & {
    message: MailResult,
    loading: boolean,
    error?: BennoError,
    token?: string,
    contentDivRef?: RefObject<HTMLDivElement>,
    scrollbarsRef?: RefObject<Scrollbars>
}> = ({message, token, loading, error, t, contentDivRef, scrollbarsRef}) => {
    return <div className='stage_content' ref={contentDivRef} id='messageContentPrintRoot'>
        {loading && <PulseLoader className='message-loader' color='#df0b04' size={11}/>}
        <div id='mailcontent_content' className={loading ? 'message-loading' : ''}>
            <div id='mailcontent_content_inner'>
                {message && <MessageBodyContent result={message} token={token} scrollbarsRef={scrollbarsRef}/>}
            </div>
        </div>
    </div>
}

export const MessageContent = withTranslation()(MessageContentComponent)
