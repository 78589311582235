import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {User} from "../types/User";
import {TokenExpiredError} from "../backend/BennoBackend";
import {BennoError} from "./common";

export interface UsersState {
    users?: Array<User>;
    currentUser?: User;
    loading: boolean;
    errors?: BennoError;
}

const initialState: UsersState = {
    loading: false,
}

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setErrors: (state, {payload}: PayloadAction<BennoError>) => {
            state.errors = payload
        },
        setUsers: (state, {payload}: PayloadAction<Array<User>>) => {
            state.users = payload
        },
        setCurrentUser: (state, {payload}: PayloadAction<User>) => {
            state.currentUser = payload
        },
        replaceUser: (state, {payload}: PayloadAction<User>) => {
            if (state.currentUser) {
                state.currentUser = state.currentUser.username == payload.username ? state.currentUser : payload
            }
            state.users = state.users?.map((user) => {
                return user.username == payload.username ? payload : user
            })
        },
        addUser: (state, {payload}: PayloadAction<User>) => {
            state.users?.push(payload)
        }
    },
})

export const {setLoading, setErrors, setUsers, replaceUser, setCurrentUser} = usersSlice.actions

export default usersSlice.reducer
