import {default as ReactDatePicker, ReactDatePickerProps} from "react-datepicker";
import moment from "moment";
import React, {RefObject, useEffect, useRef} from "react";
import {convertMomentToDateFnsFormat} from "./i18n";
import i18next from "i18next";

export const DatePicker = React.forwardRef<ReactDatePicker, ReactDatePickerProps>((props, ref) => {
    const dateFormat = convertMomentToDateFnsFormat(moment.localeData().longDateFormat('L'));
    const timeFormat = convertMomentToDateFnsFormat(moment.localeData().longDateFormat('LT'));
    return <ReactDatePicker {...props}
                            ref={ref}
                            popperPlacement='top-end' portalId='popper'
                            className='input_text date-pick'
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={i18next.language}
                            fixedHeight
                            showWeekNumbers
                            dateFormat={dateFormat + (props.showTimeSelect ? ` ${timeFormat}` : '')}/>
});
