import * as React from "react";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {HOME_PATH, PROFILE_PATH} from "../routes";
import {setSearchFormHidden} from "../store/SearchParametersSlice";
import {UserRole} from "../types/User";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../store/store";
import logo from './header/logo_benno.jpg'
import {BennoInfoDialog} from "./BennoInfoDialog";
import {useAuth} from "react-oidc-context";
import {toUpper} from "ramda";
import {getActivePage} from "../routes/search";
import {AccessTokenData, decodeAccessToken, userManager} from "../Secure";
import {toggleSearchExplicitlyOpened, toogleSubnavOpen} from "../store/UiStateSlice";

const _Sidebar: React.FC<WithTranslation & { searchFormHidden: boolean, subnavOpen: boolean }> =
    ({
         t,
         searchFormHidden,
         subnavOpen
     }) => {
        const dispatch = useDispatch()
        const auth = useAuth()
        const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false)
        const [accessToken, setAccessToken] = useState<AccessTokenData | null>(null)
        const path = useLocation().pathname
        const activePage = getActivePage(path)

        function pageActiveClassName(name: string) {
            return activePage === name && !searchFormHidden ? 'active' : ''
        }

        function toNameAbbrev(name: string | undefined) {
            if (name) {
                return name.split(' ').map(n => toUpper(n[0])).join('')
            }
            return ''
        }

        useEffect(() => {
            if (auth.user?.access_token) {
                setAccessToken(decodeAccessToken(auth.user?.access_token))
            }
        }, [auth.user?.access_token])

        return <>
            {infoDialogOpen && <BennoInfoDialog onClose={() => setInfoDialogOpen(false)}/>}
            <div id='sidebar' className={subnavOpen ? ' b-subnav-open' : ''}>

                <div id='sidebar_logo'><Link tabIndex={-1} to={HOME_PATH}><img alt='Benno Mail Archiv'
                                                                               src={logo}/></Link></div>
                <ul id='sidebar_top_navigation' className='sidebar_nav'>
                    <li className={pageActiveClassName('search')}><Link tabIndex={-1}
                                                                        to={'/'}
                                                                        onClick={evt => {
                                                                            dispatch(setSearchFormHidden(false));
                                                                            dispatch(toggleSearchExplicitlyOpened())
                                                                        }}
                                                                        title={t('TXT_SIDEBAR_SEARCH')}><span
                        className="icon-search3"></span></Link></li>
                    <li className={pageActiveClassName('queries')}>
                        {activePage == 'queries' ? <Link tabIndex={-1} to='/' title={t('TXT_SIDEBAR_QUERIES')}><span
                                className="icon-database-edit"></span></Link> :
                            <Link tabIndex={-1} to='/queries'
                                  onClick={evt => dispatch(setSearchFormHidden(false))}
                                  title={t('TXT_SIDEBAR_QUERIES')}><span
                                className="icon-database-edit"></span></Link>
                        }
                    </li>
                </ul>

                <span id="sidebar_mobile_menu"><Link tabIndex={-1} to='#'
                                                     onClick={() => dispatch(toogleSubnavOpen())}><i
                    className="fa fa-bars" aria-hidden="true"></i></Link></span>

                <ul id='sidebar_bottom_navigation' className='sidebar_nav'>
                    <li><a tabIndex={-1} onClick={(evt) => {
                        evt.preventDefault()
                        setInfoDialogOpen(!infoDialogOpen)
                    }} title={t('TXT_SIDEBAR_VERSION')}><span
                        className="icon icon-info"></span></a></li>

                    {accessToken && accessToken.roles.includes(UserRole.ADMIN) &&
                        <li className={pageActiveClassName('admin')}>
                            <Link tabIndex={-1} to={accessToken.keycloakAdminUrl} title={t('TXT_SIDEBAR_SETTINGS')}>
                                <span className="icon icon-users4"></span>
                            </Link>
                        </li>
                    }

                    <li className={pageActiveClassName('profile')} id="sidebar_bottom_navigation_user"><Link
                        tabIndex={-1}
                        to={PROFILE_PATH}
                        title={auth.user?.profile.name}>
                        <span className="icon-user3"></span>{toNameAbbrev(auth.user?.profile.name)}</Link></li>
                    <li id="sidebar_bottom_navigation_exit">
                        <a id='logout' className='btn btn-primary btn-block' onClick={() => {
                            auth.revokeTokens().then(() => {
                                userManager.signoutRedirect().then(() => {
                                    userManager.clearStaleState()
                                })
                            })
                        }}><span className="icon-exit"></span></a>
                    </li>
                </ul>
            </div>
        </>
    }

export const Sidebar = withTranslation()(connect((rootState: RootState) => {
    return {
        searchFormHidden: rootState.searchParameters.searchFormHidden,
        subnavOpen: rootState.uiState.subnavOpen
    }
})(_Sidebar))
