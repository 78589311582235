import {User} from "../types/User";
import {SavedSearch} from "../store/SavedSearchesSlice";
import {SearchFilter} from "../types/SearchFilter";
import {SearchFormType} from "../ui/search/SearchPanel";
import {MailResult} from "../types/MailResult";
import {BennoSystemInfo} from "../types/BennoSystemInfo";
import {PagedResponse} from "./RestBennoBackend";
import {MessageHeader} from "../types/MessageHeader";
import {SortingValue} from "../store/thunks";

export interface SearchResult extends PagedResponse {
    totalFoundItems: number;

    result: Array<SearchHit>;
}

export interface SearchHit extends MessageHeader {
}

export type ForwardEmailRequest = {
    bennoId: string,
    container: string,
    recipientAddresses: Array<string>
}

export type ForwardEmailResponse = {
    bennoId: string,
    container: string,
    messageId: string, sentDate: string,
    toRecipients: Array<string>
}

export type Permatoken = {
    permatoken: string;
    bennoMailId: string;
    container: string;
    dateTo: number;
};

export type PermaLinkRequest = {
    // Mail ID
    bennoId: string;
    // Name of container
    container: string;
    // Expiry date of Permatoken (unix timestamp format)
    expiryDate: number;
};

export class BennoBackendError extends Error {
    private readonly errorId: string;

    public constructor(errorId: string, message: string) {
        super(message);
        this.errorId = errorId;
    }

    public getErrorId(): string {
        return this.errorId;
    }
}

export class TokenExpiredError extends BennoBackendError {

    public constructor() {
        super('TOKEN_EXPIRED_ERROR', 'Token expired');
    }

}

export class InternalServerError extends BennoBackendError {

    public constructor() {
        super('INTERNAL_SERVER_ERROR', 'Internal server error');
    }
}

export class NotFoundError extends BennoBackendError {

    public constructor() {
        super('NOT_FOUND_ERROR', 'Not found');
    }
}

export class InvalidInputError extends BennoBackendError {

    public constructor() {
        super('INVALID_INPUT_ERROR', 'Invalid input');
    }
}

interface BennoBackend {
    // unauthorized --
    getMessageWithToken(token: string): Promise<MailResult>;

    search(container: string, filter: SearchFilter, searchForm: SearchFormType, pageSize: number, page: number, sorting: SortingValue): Promise<SearchResult>;

    getMessage(container: string, bennoId: string): Promise<MailResult>;

    getSavedSearches(): Promise<Array<SavedSearch>>;

    addSavedSearch(name: string, searchFilter: SearchFilter): Promise<SavedSearch>;

    deleteSavedSearch(searchId: string): Promise<void>;

    forwardEmail(request: ForwardEmailRequest): Promise<ForwardEmailResponse>;

    createPermaToken(request: PermaLinkRequest): Promise<Permatoken>;

    getAttachment(bennoId: string, bennoContainer: string, attachmentId: number): Promise<Blob>;

    getSystemInfo(): Promise<BennoSystemInfo>;
}

export default BennoBackend

