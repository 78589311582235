import {useRef} from "react";
import {useReactToPrint} from "react-to-print";
import {Scrollbars} from "rc-scrollbars";
import {cloneShadowRoots} from "../cloneEmail";

function calculateContentHeight(iframeDoc: Document, scrollbarsRef: React.MutableRefObject<Scrollbars | null>) {
    let totalContentHeight = 0;

    const mailcontentHeader = iframeDoc.getElementById('mailcontent_header');
    if (mailcontentHeader) {
        totalContentHeight += mailcontentHeader.offsetHeight;
    }
    const mailcontentAttachments = iframeDoc.getElementById('mailcontent_attachments');
    if (mailcontentAttachments) {
        totalContentHeight += mailcontentAttachments.offsetHeight;
    }

    totalContentHeight += scrollbarsRef.current!!.getScrollHeight();
    return totalContentHeight;
}

export const PrintSetup = (contentDivRef: React.RefObject<HTMLDivElement>) => {
    const scrollbarsRef = useRef<Scrollbars>(null);

    const onPrint = useReactToPrint({
        onBeforePrint: () => {
            const printWindow = document.getElementById('printWindow') as HTMLIFrameElement;
            printWindow.style.height = printWindow.contentWindow!!.document.body.scrollHeight + 'px';
            const iframeDoc = printWindow.contentDocument!!;

            let totalContentHeight = calculateContentHeight(iframeDoc, scrollbarsRef);
            const mailContentElement = iframeDoc.getElementById('mailcontent_inner');
            if (mailContentElement) {
                mailContentElement.style.height = `${totalContentHeight}px`;
            }

            const sourceElement = contentDivRef.current!!;
            const rootElement = iframeDoc.getElementById(sourceElement.id)!!
            cloneShadowRoots(sourceElement, rootElement)
        },
        content: () => contentDivRef.current!!
    });

    return {scrollbarsRef, onPrint};
};